.SidebarContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  align-items: center;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out;

  .Icon {
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
    color: #fff;
    transition: 0.2s ease-in-out;

    &:hover {
      color: #54859f;
      transition: 0.2s ease-in-out;
    }
  }

  .SidebarWrapper {
    color: #fff;

    .SidebarMenu {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(6, 80px);
      text-align: center;

      @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
      }

      .SidebarLink {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;
        text-decoration: none;
        list-style: none;
        transition: 0.2s ease-in-out;
        color: #fff;
        cursor: pointer;

        &:hover {
          color: #54859f;
          transition: 0.2s ease-in-out;
        }

        img {
          height: 100px;
          margin-bottom: 25px;
        }
      }
    }
  }
}
