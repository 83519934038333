.AboutContainer {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(203, 203, 203, 1) 30%
  );
  color: #2d2c2e;
  width: 100%;
  height: calc(80vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  box-shadow: inset 0 4px 2px -2px gray;

  @media screen and (max-width: 1024px) {
    transition: all 0.8s ease;
  }

  .AboutHero {
    display: flex;
    justify-content: center;
    align-items: center;

    .LeftSide {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-self: center;

      @media screen and (max-width: 820px) {
        width: 75%;
      }

      h2 {
        margin-bottom: 4%;
        font-size: 1.6em;
        font-weight: 300;
      }

      p {
        font-size: 1.1em;
        font-weight: 300;
        line-height: 1.8;
      }
    }
  }
}
