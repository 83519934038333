.ExperienceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 80px);
  width: 100%;
  overflow: hidden;
  box-shadow: inset 0 4px 2px -2px gray;

  @media screen and (max-width: 1024px) {
    transition: all 0.8s ease;
  }

  .TitleWrapper {
    font-size: 20px;
    padding: 20px 0 20px 0;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #54859f;

    h4 {
      font-weight: 500;
    }
  }

  .ExperienceGrid {
    width: 100vw;
    height: 100%;
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 15px;

    .LeftSide {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      text-align: end;
      border-right: 1px solid #54859f;

      .JobWrapper {
        margin-right: 40px;

        .dates {
          font-weight: bold;
          padding-bottom: 5px;
        }

        .JobTitle {
          font-weight: 300;
          font-style: italic;
        }
      }
    }

    .RightSide {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      justify-items: start;
      font-weight: 200;

      .JobWrapper {
        .Employer {
          padding: 0 300px 5px 40px;
          font-style: italic;
        }

        .JobDescription {
          padding: 0 300px 0 40px;
          line-height: 1.8;

          @media screen and (max-width: 1024px) {
            font-size: 10px;
          }

          @media screen and (max-width: 820px) {
            padding: 0 100px 0 40px;
          }

          @media screen and (max-width: 450px) {
            padding: 0 180px 0 40px;
          }

          @media screen and (max-width: 375px) {
            padding: 0 200px 0 40px;
          }
        }
      }
    }
  }
}
