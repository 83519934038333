.Nav {
  background: #03102d;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 1024px) {
    transition: all 0.8s ease;
  }

  .NavbarContainer {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
    max-width: 1200px;

    .NavLogo {
      color: #fff;
      justify-self: flex-start;
      cursor: pointer;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      margin-left: 24px;
      font-weight: bold;
      text-decoration: none;

      img {
        height: 80px;
      }
    }

    .MobileIcon {
      display: none;

      @media screen and (max-width: 820px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 40%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
      }
    }

    .NavMenu {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      list-style: none;
      text-align: right;
      margin-right: -22px;
      width: 80%;
      font-size: 1rem;

      @media screen and (max-width: 820px) {
        display: none;
      }

      .NavItem {
        height: 80px;
        margin-left: 15px;

        .NavLink {
          color: #fff;
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 0 1rem;
          height: 100%;
          cursor: pointer;
          transition: 0s ease-in-out;

          &.active {
            color: #54859f;
          }

          &:hover {
            color: #54859f;
          }
        }
      }
    }
  }
}
