.ContactContainer {
  background: #03102d url("../components/images/white-bg.jpg") no-repeat fixed
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 80px);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  box-shadow: inset 0 4px 2px -2px gray;

  .GridSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .ContactLeft {
    line-height: 1.5;

    .ContactInfo {
      .ContactName {
        font-size: 30px;
      }

      .ContactSubName {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 20px;
      }

      .Info {
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 6px;
      }

      .Socials {
        border-top: 1px solid #000;
        padding-top: 10px;

        a {
          color: #000;
          font-size: 1.6em;
          text-decoration: none;
        }
      }
    }
  }

  .ContactRight {
    box-shadow: inset 0 0 2px 2px gray;
    background: #fff;
    height: 100%;
    width: 100%;

    form {
      display: grid;
      height: 100%;
      width: 100%;
      padding: 40px;
    }
  }
}
