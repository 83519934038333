.HeroContainer {
  background: #03102d url("../components/images/tech_pic.jpg") no-repeat fixed
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: calc(100vh - 80px);
  position: relative;
  z-index: 1;
  background-position: fixed;

  @media screen and (max-width: 1024px) {
    transition: all 0.8s ease;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }

  .HeroContentGrid {
    background-color: white;
    width: 65%;
    height: 80%;
    z-index: 3;
    position: absolute;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media screen and (max-width: 1024px) {
      grid-template-columns: 2fr 1fr;
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr;
      grid-template-rows: 2fr 1fr;
    }

    .HeroLeft {
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: contain;
        filter: grayscale(90%);
      }
    }

    .HeroRight {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20%;

      @media screen and (max-width: 1024px) {
        font-size: 0.4em;
        margin-left: 5%;
      }

      @media screen and (max-width: 450px) {
        margin-left: 10px;
        font-size: 0.5em;
      }

      .HeroTitle {
        margin-bottom: 10%;

        @media screen and (max-width: 1024px) {
          margin-bottom: 5%;
        }
      }

      .HeroInfo {
        margin-bottom: 5%;

        @media screen and (max-width: 1024px) {
          margin-bottom: 0;
        }

        .title {
          font-weight: bold;
        }

        a {
          text-decoration: none;
          color: #000;
        }
      }

      .Socials {
        display: flex;

        a {
          color: #000;
          font-size: 2em;
          text-decoration: none;
          margin-right: 10px;
        }
      }
    }
  }
}
