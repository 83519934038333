.SkillsContainer {
  background: #03102d url("../components/images/dark-paths.png") no-repeat fixed
    center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
  color: #fff;
  overflow: hidden;

  @media screen and (max-width: 1024px) {
    transition: all 0.8s ease;
  }

  .SkillsTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 30px;

    h5 {
      font-size: 20px;
      font-weight: 400;
    }
  }

  ul {
    list-style-type: none;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SkillsHero {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px 150px 230px 150px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 820px) {
      padding: 20px 150px 230px 100px;
    }

    @media screen and (max-width: 450px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 390px) {
      padding: 20px 150px 230px 80px;
    }

    .SkillsCol {
      height: 100%;
      width: 100%;
      display: grid;
      grid-template-rows: repeat(5, 1fr);

      .Skill {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        width: 303px;
        background-color: rgba(255, 255, 255, 0.15);
        font-size: 14px;

        @media screen and (max-width: 820px) {
          width: 260px;
        }

        @media screen and (max-width: 768px) {
          width: 245px;
        }

        @media screen and (max-width: 450px) {
          height: 40px;
        }

        @media screen and (max-width: 390px) {
          height: 35px;
        }
      }
    }
  }
}
